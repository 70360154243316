import React from 'react';

const SizeSelector = ({ selectedSize, onChange }) => {
  const sizes = [25,50,100,150];

  return (
    <div className='pi pi-arrows-alt' 
        style={{ margin: '10px 0', padding: '8px 25px', background: '#696969', borderRadius: '5px', color: '#FFF', fontSize: '18px' }}>
      <select
        id="sizeSelector"
        value={selectedSize}
        onChange={(e) => onChange(Number(e.target.value))}
        style={{ marginLeft: '10px', cursor: 'pointer' }}
      >
        {sizes.map((size) => (
          <option key={size} value={size}>
            {size * 2} metros
          </option>
        ))}
      </select>
    </div>
  );
};

export default SizeSelector;
