import styled from "styled-components";

export const ModalTitle = styled.h2`
  margin-top: 10px;
`;
export const SectorName = styled.h3`
  font-size: 2rem;
  font-weight: 400;
  padding-left: 10px;
`;

export const FormContainer = styled.div`
  gap: 1rem;
  padding: 1rem;
  width: 100%;

  .p-dropdown .p-dropdown-label.p-placeholder {
    color: #555 !important;
    opacity: 0.8;
  }
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;

export const Label = styled.label`
  font-weight: 400;
  color: #333;
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1rem;
`;

export const Button = styled.button`
  padding: 0.5rem 1rem;
  border-radius: 4px;
  border: none;
  cursor: pointer;

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;


export const ConfirmButton = styled.div`
  cursor: pointer;
  text-align: center;
  font-weight: bolder;
  background-color: #6eff94;
  width: 50%;
  margin: 0 auto;
  border-radius: 5px;
  padding: 3px;
  transition: all 0.3s ease;

  &:hover {
    background-color: #3fcc65;
  }
`;

export const StyledButton = styled(Button)`
  &:enabled {
    background-color: ${props => props.$isValid ? '#6eff94' : '#E6E6E6'} !important;
    border: none !important;
    border-radius: 5px !important;
    transition: all 0.3s ease !important;
    opacity: ${props => props.$isSubmitting ? 0.7 : 1} !important;
    cursor: ${props => props.$isSubmitting ? 'wait' : 'pointer'} !important;

    &:hover {
      background-color: ${props => props.$isValid ? '#3fcc65' : '#E6E6E6'} !important;
    }
  }
`;
