import React, { useState, useEffect, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Toast } from "primereact/toast";
import ImageUploader from "../ImageUploader";
import DropdownCompAlarm from "../Dropdown/Alarm";
import { type_alarms, create_alarms } from "../../../services/alarms";
import { allSectors as sectors } from "../../../services/dashboard";
import useCompanySectorStore from "../../../store/useCompanySectorStore";
import {
  FormContainer,
  FormGroup,
  Label,
  ButtonGroup,
  Button,
  ModalTitle,
  SectorName,
  ConfirmButton,
  StyledButton,
} from "./styles";
import { addLocale } from "primereact/api";
import AlarmCreatorMap from "../../Maps/CreateAlarm";
const CreateAlarmModal = ({ visible, onHide, clientId, defaultCenter, showTraces, customMarkerIcon, kindPin, idDeviceChosen, polygonsPros, valueScaleColor }) => {
  const toast = useRef(null);
  const overlayRef = useRef(null);
  const { sectorIdSelected, setSectorIdSelected } = useCompanySectorStore();
  const [selectedAlarm, setSelectedAlarm] = useState(null);
  const [datetime, setDatetime] = useState(new Date());
  const [sectorName, setSectorName] = useState("");
  const [alarmType, setAlarmType] = useState(null);
  const [isFormValid, setIsFormValid] = useState(false);
  const [base64Image, setBase64Image] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (visible) {
      getAlarms();
      getSectors(clientId);
    }
  }, [visible, clientId]);

  useEffect(() => {
    validateForm();
  }, [selectedAlarm, datetime, base64Image]);
  const validateForm = () => {
    const isValid =
      selectedAlarm &&
      datetime &&
      base64Image !== "";

    setIsFormValid(isValid);
  };



  const getAlarms = async () => {
    try {
      const resp = await type_alarms();
      setAlarmType(resp.data);
    } catch (error) {
      if (error.response) {
        const msg = error.response.data.message;
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: msg,
          life: 3000,
        });
      } else if (error.request) {
        const message = error.message;
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: message,
          life: 3000,
        });
      }
    }
  };

  const getSectors = async (data) => {
    const temp = {
      clientId: data,
    };
    try {
      const resp = await sectors(temp);
      const sector = resp.data.find(
        (sector) => sector.sectorId === sectorIdSelected
      );
      setSectorName(sector.sectorName);
    } catch (error) {
      if (error.response) {
        const msg = error.response.data.message;
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: msg,
          life: 3000,
        });
      } else if (error.request) {
        const message = error.message;
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: message,
          life: 3000,
        });
      }
    }
  };

  const handleDropdownChangeAlarm = (e) => {
    setSelectedAlarm(e.value);
  };

  addLocale("pt-BR", {
    firstDayOfWeek: 0,
    dayNames: [
      "domingo",
      "segunda-feira",
      "terça-feira",
      "quarta-feira",
      "quinta-feira",
      "sexta-feira",
      "sábado",
    ],
    dayNamesShort: ["dom", "seg", "ter", "qua", "qui", "sex", "sáb"],
    dayNamesMin: ["D", "S", "T", "Q", "Q", "S", "S"],
    monthNames: [
      "janeiro",
      "fevereiro",
      "março",
      "abril",
      "maio",
      "junho",
      "julho",
      "agosto",
      "setembro",
      "outubro",
      "novembro",
      "dezembro",
    ],
    monthNamesShort: [
      "jan",
      "fev",
      "mar",
      "abr",
      "mai",
      "jun",
      "jul",
      "ago",
      "set",
      "out",
      "nov",
      "dez",
    ],
    today: "Agora ",
    clear: "Limpar",
   
    
  });

  const resetForm = () => {
    setSelectedAlarm(null);
    setDatetime(new Date());
    setBase64Image("");
    setIsFormValid(false);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);

    const payload = {
      clientId: clientId,
      alarmTypeId: selectedAlarm,
      sectorId: sectorIdSelected,
      alarmNoteDate: datetime.toISOString(),
      alarmNoteImg: base64Image,
      seen: false,
      removedByUser: false,
      deletedByOperation: false,
      showToUser: true,
    };

    try {
      await create_alarms(payload);
      toast.current.show({
        severity: "success",
        summary: "Sucesso",
        detail: "Alarme criado com sucesso.",
        life: 3000,
      });
handleHide()
    } catch (error) {
      if (error.response) {
        const msg = error.response.data.message;
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: msg,
          life: 3000,
        });
      } else if (error.request) {
        const message = error.message;
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: message,
          life: 3000,
        });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleHide = () => {
    resetForm();
    onHide();
  };

  const handleSelectDate = () => {
    if (!datetime) {
      setDatetime(new Date());
    }
    return (
      <div style={{ display: 'flex', justifyContent: 'center', padding: '0.5rem' }}>
        <ConfirmButton 
          type="button"
          onClick={() => {
            if (!datetime) {
              setDatetime(new Date());
            }
            overlayRef.current.hide();
          }}
        >
          Confirmar
        </ConfirmButton>
      </div>
    );
  };

  const handleMapSnapshot = (imgData) => {
    setBase64Image(imgData);
  };

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        visible={visible}
        onHide={!isSubmitting ? handleHide : undefined}
        header={<ModalTitle>Criação de alarme</ModalTitle>}
        style={{ width: "80vw", height: "90vh" }}
        modal
        className="p-fluid"
        closable={!isSubmitting}
      >
        <div className="row">
          <div className="col-lg-7 col-md-12" style={{  textAlign: "center" }}>
            <AlarmCreatorMap
              defaultCenter={defaultCenter}
              showTraces={showTraces}
              customMarkerIcon={customMarkerIcon}
              kindPin={kindPin}
              idDeviceChosen={idDeviceChosen}
              polygonsPros={polygonsPros}
              valueScaleColor={valueScaleColor}
              onSnapshot={handleMapSnapshot}
            />
          </div>

          <div className="col-lg-5 col-md-12 d-flex flex-column align-items-center justify-content-start">
            <SectorName>{`Setor: ${sectorName}`}</SectorName>
            <FormContainer>
              <FormGroup>
                <Label>Tipo de Alarme</Label>
                <DropdownCompAlarm
                  value={selectedAlarm}
                  handleDropdownChangeAlarm={handleDropdownChangeAlarm}
                  data={alarmType}
                />
              </FormGroup>

              <FormGroup>
                <Label>Data e Hora</Label>
                <Calendar
                  value={datetime}
                  ref={overlayRef}
                  onChange={(e) => setDatetime(e.value)}
                  showTime
                  showSeconds={false}
                  dateFormat="dd/mm/yy"
                  hourFormat="24"
                  showIcon
                  showButtonBar
                  placeholder="Selecione a data e hora"
                  locale="pt-BR"
                  footerTemplate={handleSelectDate}
                />
              </FormGroup>
             
              <ButtonGroup>
                <Button 
                  className="btn btn-danger" 
                  onClick={handleHide}
                  disabled={isSubmitting}
                >
                  Cancelar
                </Button>
                <StyledButton
                  onClick={handleSubmit}
                  disabled={!isFormValid || isSubmitting}
                  $isValid={isFormValid}
                  $isSubmitting={isSubmitting}
                >
                  {isSubmitting ? 'Criando...' : 'Criar Alarme'}
                </StyledButton>
              </ButtonGroup>
            </FormContainer>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default CreateAlarmModal;
